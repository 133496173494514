import {
  CAREERS, CTF, CTF_AND_EDUCATION, EVENTS, MEDIA, MARKETING,
  CREATIVE, PORTFOLIO, PROJECTS, EVENTS_AND_SCONES, EDUCATION,
  SCONES, CONFERENCES, SOCIALS,
} from 'types/CommitteeMember';

const CURR_YEAR = 2024; // Wow we need to change this every year lul
export const CURR_SPONSER_YEAR = CURR_YEAR;
export const CURR_TEAM_YEAR = CURR_YEAR;
export const PORTFOLIOS: PORTFOLIO[] = [
  CAREERS,
  EVENTS_AND_SCONES,
  EVENTS,
  CTF,
  CTF_AND_EDUCATION,
  MEDIA,
  MARKETING,
  CREATIVE,
  PROJECTS,
  EDUCATION,
  SCONES,
  CONFERENCES,
  SOCIALS,
];
export const CURR_DATE = '';
export const SHOW_PAST_EVENTS_MAX = 12;
export const SHOW_UPCOMING_EVENTS_MAX = 3;

export const NEWSLETTERS_BUCKET_URL = 'https://storage.googleapis.com/unswsecurity-website-data/newsletters-v2.json';
export const EVENTS_BUCKET_URL = 'https://storage.googleapis.com/unswsecurity-website-data/events-v2.json';
