type CommitteeMember = {
  role: string;
  position:
  | typeof EXECUTIVE
  | typeof HEAD
  | typeof COMMITTEE
  | typeof DIRECTOR
  | typeof SUBCOMMITTEE;
  portfolio?: PORTFOLIO | typeof EXECUTIVE;
  name: string;
  email?: string;
  slack?: string;
  discord?: string;
  website?: string;
  bio?: string;
};

export type SubcommitteeMember = {
  name: string;
  portfolio: PORTFOLIO;
};

export type SubcommitteePortfolio = {
  portfolio: PORTFOLIO;
  subcommittee: SubcommitteeMember[];
};

export type PORTFOLIO =
  | typeof CTF
  | typeof CTF_AND_EDUCATION
  | typeof PROJECTS
  | typeof EVENTS
  | typeof EVENTS_AND_SCONES
  | typeof MEDIA
  | typeof MARKETING
  | typeof CREATIVE
  | typeof CAREERS
  | typeof EDUCATION
  | typeof SCONES
  | typeof CONFERENCES
  | typeof SOCIALS;

export type CommitteeTeam = {
  year: number;
  executives: CommitteeMember[];
  committee?: CommitteeMember[];
  subcommittee?: SubcommitteeMember[];
};

export const EXECUTIVE = 'Executives';
export const HEAD = 'Heads';
export const COMMITTEE = 'Committee';
export const DIRECTOR = 'Directors';
export const SUBCOMMITTEE = 'Subcommittee';

export const PROJECTS = 'Projects';
export const CAREERS = 'Careers';

// Last use should be in 2021 committee. No.
export const CTF = 'CTF';
// CTF was renamed to CTF & Education in 2022.
export const CTF_AND_EDUCATION = 'CTF & Education';
// CTF & Education was split into CTF and Education in 2023.
export const EDUCATION = 'Education';

// Last use should be in 2021 committee.
export const MEDIA = 'Media';
// Media was split into creative and marketing in 2022.
export const CREATIVE = 'Creative';
export const MARKETING = 'Marketing';

// Last use should be in 2021 committee. No.
export const EVENTS = 'Events';
// Events was renamed to Events & Scones in 2022.
export const EVENTS_AND_SCONES = 'Events & Scones';
// Events & Scones was split into Events and Scones in 2023.
export const SCONES = 'Scones';

// Scones became Conferences in 2024.
export const CONFERENCES = 'Conferences';
// Events was renamed to Socials in 2024.
export const SOCIALS = 'Socials';
